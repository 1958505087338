<script setup lang="ts">
import UserMenu from "~/pages/enviro/me/components/UserMenu.vue";
import Avatar from "../Avatar.vue";


const civiqaUser = useCiviqaUser();

let loginLink = "/login";

const route = useRoute();

if (route.query.inviteCode) {
    loginLink += `?inviteCode=${route.query.inviteCode}`;
}

</script>

<template>
    <header class="bg-white">
        <nav class="relative mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex flex-1">
                <div class="shrink-0">
                    <NuxtLink to="/home">
                        <img class="h-16 w-auto" src="/horizontal.png" alt="Civiqa" />
                    </NuxtLink>
                </div>
            </div>

            <div class="flex items-center">
                <UserMenu v-if="civiqaUser" pin-location="topRight" :show-email="true">
                    <Avatar class="size-10" :name="civiqaUser.fullName" :photo-url="civiqaUser.photoUrl" :ring-size="0"
                        :no-tooltip="true" />
                </UserMenu>
                <button v-else>
                    <NuxtLink :href="loginLink"
                        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset ring-gray-300 hover:text-green-700">
                        Log in
                        <span aria-hidden="true">&rarr;</span>
                    </NuxtLink>
                </button>
            </div>
        </nav>
    </header>
</template>
